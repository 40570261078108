// Media queries
@mixin media-breakpoint-up($point) {
  @if $point == sm {
    @media all and (min-width: 576px) { @content; }
  } @else if $point == md {
    @media (min-width: 768px) { @content; }
  } @else if $point == lg {
    @media (min-width: 992px) { @content; }
  } @else if $point == xl {
    @media (min-width: 1200px) { @content; }
  } @else if $point == xxl {
    @media (min-width: 1400px) { @content; }
  } @else {
    @media (min-width: $point) { @content; }
  }
}

@mixin media-breakpoint-down($point) {
  @if $point == sm {
    @media all and (max-width: 575px) { @content; }
  } @else if $point == md {
    @media (max-width: 767px) { @content; }
  } @else if $point == lg {
    @media (max-width: 991px) { @content; }
  } @else if $point == xl {
    @media (max-width: 1199px) { @content; }
  } @else if $point == xxl {
    @media (max-width: 1399px) { @content; }
  } @else {
    @media (max-width: calc($point - 1)) { @content; }
  }
}

// Grid behaviour

@mixin col-50-in-row {
  flex: 0 0 calc(50% - 9px);
  width: calc(50% - 9px);
  padding: 0 9px;

  &:last-child {
    padding-right: 9px !important;
  }
  // &:last-child

  &:nth-last-child(2) {
    margin-bottom: 0 !important;
  }
  // &:nth-last-child(2)

  &:nth-child(odd) {
    padding-left: 0 !important;
  }
  // &:nth-child(odd)

  &:nth-child(even) {
    padding-right: 0 !important;
  }
  // &:nth-child(even)

  &:only-child {
    flex: 0 0 50%;
    width: 50%;
  }
  // &:only-child
}

// Text selection
@mixin text-selection($color-selection, $color-selection-bg) {
  ::-moz-selection { // sass-lint:disable-line no-vendor-prefixes
    background: $color-selection-bg;
    color: $color-selection;
    text-shadow: none;
  }

  ::selection {
    background: $color-selection-bg;
    color: $color-selection;
    text-shadow: none;
  }
}

// Placeholders
@mixin placeholder {
  // sass-lint:disable no-vendor-prefixes
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

// Transitions
@mixin transition( $time: 0.2s) {
  transition: all $time ease-in;
}
