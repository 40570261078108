.section-galleries-archive {

	> .container {

		> .grid {

			@include media-breakpoint-up(md) {
				flex-wrap: nowrap;
			}

			.grid {

				@media all and (orientation: landscape) and (min-width: 600px) and (max-width: 767px) {
					flex-flow: row wrap;
				}

				.col-33 {

					@media all and (orientation: landscape) and (min-width: 600px) and (max-width: 767px) {
						@include col-50-in-row;
					}
				}
				// .col-33
			}
			// .grid
		}
		// > .grid
	}
	// > .container

	.col-sidebar {
		padding: 30px 0 34px;

		@include media-breakpoint-up(md) {
			padding: 50px 22px 50px 12px;
		}
		@include media-breakpoint-up(lg) {
			padding-right: 44px;
		}
		@include media-breakpoint-up(xl) {
			padding-left: 34px;
		}
	}
	// .col-sidebar

	.col-main {

		@include media-breakpoint-up(md) {
			padding: 50px 0 3rem 22px;
		}
		@include media-breakpoint-up(xl) {
			padding-right: 34px;
		}
		@include media-breakpoint-up(xxl) {
			padding-left: 44px;
		}

		.sorting-panel {
			margin-bottom: 50px;

			@include media-breakpoint-up(md) {
				float: right;
				margin-bottom: 40px;
			}
		}
		// .sorting-panel
	}
	// .col-main

	.post-card {

		picture {
			margin-bottom: 0.75rem;

			&:after {
				display: none;
			}
			// &:after
		}
		// picture

		.post-card-wrapper {
			min-height: 0;
			padding: 0;
		}
		// .post-card-wrapper

		.post-title {
			font-size: 1.125rem;
			margin-bottom: 0;
		}
		// .post-title
	}
	// .post-card
}
// .section-galleries-archive
