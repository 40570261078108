.section-news {

	> .container {

		> .grid {

			> .col-33 {
				margin-bottom: 50px;
			}
			// > .col-33

			.grid {

				@media all and (orientation: landscape) and (min-width: 600px) and (max-width: 767px) {
					flex-flow: row wrap;
				}

				.col-50 {

					@media all and (orientation: landscape) and (min-width: 600px) and (max-width: 767px) {
						@include col-50-in-row;
					}
				}
				// .col-50
			}
			// .grid
		}
		// > .grid
	}
	// > .container

	.post-list {
		overflow: hidden;
		position: relative;

		&.bg-white {
			height: 100%;
		}
		// &.bg-white

		&.clipped {

			&:before {
				display: block;
				content: '';
				width: 100%;
				height: 250px;
				background: linear-gradient(to bottom, rgba($white, 0.125) 12.5%, rgba($white, 0.25) 25%, rgba($white, 0.5) 50%, $white 70%, $white);
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 2;
			}
			// &:before

			&:after {
				display: block;
//				content: '';
				width: 100%;
				height: 160px;
				backdrop-filter: blur(1px);
				opacity: 0.5;
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 3;
			}
			// &:after
		}
		// &.clipped

		.section-link {
			margin-bottom: 0;
			@include media-breakpoint-up(md) {
				position: absolute;
				bottom: 22px;
				z-index: 9;
			}
			@include media-breakpoint-up(lg) {
				bottom: 19px;
			}
		}
		// .section-link
	}
	// .post-list
}
// .section-news
