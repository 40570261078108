.section-single-author {

	> .container {

		> .grid {

			@media all and (orientation: landscape) and (min-width: 600px) {
				flex-flow: row nowrap;
			}
			@include media-breakpoint-up(md) {
				flex-wrap: nowrap;
			}
		}
		// > .grid
	}
	// > .container

	.col-sidebar {
		padding: 1.25rem 22px 30px;
		margin: 0 -22px;

		@media all and (orientation: landscape) and (min-width: 600px) {
			margin: 0;
		}
		@include media-breakpoint-up(md) {
			margin: 0;
		}
		@include media-breakpoint-up(lg) {
			padding: 1.25rem 2rem 2rem 2.25rem;
		}
	}
	// .col-sidebar

	.col-main {

		@media all and (orientation: landscape) and (min-width: 600px) {
			padding-top: 27px;
		}
		@include media-breakpoint-up(md) {
			padding-top: 27px;
		}
		@include media-breakpoint-up(lg) {
			padding-left: 2rem;
		}
		@include media-breakpoint-up(xl) {
			padding-right: 2.25rem;
		}

		.sorting-panel {

			@media all and (orientation: landscape) and (min-width: 600px) {
				float: right;
			}
			@include media-breakpoint-up(md) {
				float: right;
			}
		}
		// .sorting-panel

		.posts-grid {
			clear: both;
			margin-bottom: 50px;

			@media all and (orientation: landscape) and (min-width: 600px) {
				flex-flow: row wrap;
			}

			> .col-33 {

				@media all and (orientation: landscape) and (min-width: 600px) and (max-width: 767px) {
					@include col-50-in-row;
				}
			}
			// > .col-33
		}
		// .posts-grid
	}
	// .col-main

	.post-card {

		picture {
			margin-bottom: 0.75rem;

			&:after {
				display: none;
			}
			// &:after
		}
		// picture

		.post-card-wrapper {
			min-height: 0;
			padding: 0;
		}
		// .post-card-wrapper

		.post-title {
			font-size: 1.125rem;
			margin-bottom: 0;
		}
		// .post-title
	}
	// .post-card
	// .section-link
}
// .section-single-author
.section-link {
	display: block;
	font-size: 0.75rem;
	text-transform: uppercase;
	text-decoration: underline;
	color: rgba($black, 0.6);
	text-align: center;
	margin-bottom: 50px;
}
