.wp-block-video.result2023 video{
  width: 1280px;
  @media (max-width: 1024px) {
    max-width: 100%;
    height: auto;
  }
}

.inner_wrapper {
  max-width: 1280px;
  width: 100%;
  margin: auto;
  @media (max-width: 1024px) {
    padding: 0 15px;
  }
}

.bg-blue2 {
  background-color: #2E6DE8;
}
.bg-brown {
  background-color: #6B6B6B;
}

.flex {
  display: flex;
  flex-wrap: wrap;

  .col-flex-1 {
    width: 8%;
    padding: 0;
  }
  .col-flex-2 {
    width: 16%;
    padding: 0;
  }
  .col-flex-5 {
    width: 40%;
    padding: 0;
  }
  .col-flex-6 {
    width: 50%;
    padding: 0;
  }
  .col-flex-8 {
    width: 68%;
    padding: 0;
  }

  @media (max-width: 1024px) {
    .col-flex-6 {
      width: 100%;
      padding: 0;
    }
  }

}

.relative {
  position: relative;
}

.pl-40{
  padding-left: 40px;
}
.pt-70 {
  padding-top: 70px;
}
.pr-160 {
  padding-right: 160px;
}
.pb-15 {
  padding-bottom: 15px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-70 {
  margin-top: 70px;
}

.lh-normal {
  line-height: 32px;
}
.strong {
  font-weight: 700;
}
.text-center {
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}
.fs-99 {
  font-family: DIN Pro Condensed;
  font-size: 99px;
  font-style: normal;
  font-weight: 700;
}
.fs-38 {
  font-size: 38px;
}
.fs-24 {
  font-size: 24px;
}
.fs-16px {
  font-size: 16px;
}

@media only screen and (max-width: 1480px) {
  .fs-99 {
    font-family: DIN Pro Condensed;
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
  }
  .fs-38 {
    font-size: 20px;
  }
}

.container-slider {
  .slider-text {
    @media only screen and (min-width: 1280px) {
      .slider-text {
        padding: 106px 25px 53px 163px;
      }
    }
    padding: 15px;
    .swiper-pagination1{
      left: 750px;
      bottom: 10px;
      position: absolute;
      @media (max-width: 1024px) {
        visibility: hidden;
      }
      .swiper-pagination-bullet {
        border-radius: 0 !important;
        background-color: $white;
        width: 7px;
        height: 7px;
        opacity: 100%;
      }
      .swiper-pagination-bullet-active {
        background-color: #91E5FF;
      }
    }
  }
}

.results2023-swiper1 {
  width: 100%;
  height: 100%;
  background: no-repeat center;
  background-size: cover;
  overflow: hidden;
  @media (max-width: 1024px) {
    max-width: 1024px;
    width: 100%;
    height: 400px;
  }
}
  .slider-image {
    background: no-repeat center;
    background-size: cover;
    overflow: hidden;
    @media (max-width: 1024px) {
      width:  100%;
      height: 400px;
  }
}

.results2023-swiper2, .results2023-swiper3, .results2023-swiper4{
  width: 100%;
  height: 860px;
  background: no-repeat center;
  background-size: cover;
  overflow: hidden;
  @media (max-width: 1024px) {
    max-width: 1024px;
    width: 100%;
    height: 400px;
  }
  .slider-image {
    background: no-repeat center;
    background-size: cover;
    overflow: hidden;
    @media (max-width: 1024px) {
      width: 100%;
      height: 400px;
    }
  }
  .swiper-pagination2, .swiper-pagination3, .swiper-pagination4 {
    bottom: 20px;
    left: 1000px;
    z-index: 1;
    position: absolute;
    @media (max-width: 1024px) {
      visibility: hidden;
    }

    .swiper-pagination-bullet {
      border-radius: 0 !important;
      background-color: $white;
      width: 7px;
      height: 7px;
      opacity: 100%;
    }
    .swiper-pagination-bullet-active {
      background-color: #91E5FF;
    }
  }
}

.bg-builder {
  background-image: url('https://moscowchanges.ru/wp-content/themes/moschange/static/img/longreads/results2023/builder1.jpg');
  background-repeat: no-repeat;
  width: 100%;
  min-height: 578px;
  @media (max-width: 1024px) {
    .ps-15 {
      padding: 15px;
    }
  }
}

.info-container {

}

.line {
  .face img {
    @media only screen and (min-width: 1280px) {
      height: 209px;
      width: 189px;
    }
  }
  .quote {
    @media only screen and (min-width: 1280px) {
      width: 1000px;
      margin-left: 45px;
    }
    align-self: center;
    @media (max-width: 1024px) {
      padding: 15px;
      width: 100%;
    }
  }
  @media (max-width: 1279px) {
    .face img {
      padding: 15px;
    }
  }
}