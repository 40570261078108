.section-post {

	.col-sidebar {

		@include media-breakpoint-up(md) {
			flex: 0 0 300px;
			width: 300px;

			&:first-child {
				padding-right: 9px;
			}
			// &:first-child

			&:last-child {
				padding-left: 9px;
			}
			// &:last-child
		}
		@include media-breakpoint-up(lg) {
			flex: 0 0 385px;
			width: 385px;
		}
		@include media-breakpoint-up(xl) {

			&:first-child {
				padding-right: 15px;
			}
			// &:first-child

			&:last-child {
				padding-left: 15px;
			}
			// &:last-child
		}
	}
	// .col-sidebar

	.col-main {
		margin: 0 -22px 50px;

		@include media-breakpoint-up(md) {
			flex: 1 1 calc(100% - 300px);
			width: calc(100% - 300px);
			margin: 0;

			&:first-child {
				padding-right: 9px;
			}
			// &:first-child

			&:last-child {
				padding-left: 9px;
			}
			// &:last-child
		}
		@include media-breakpoint-up(lg) {
			flex: 1 1 calc(100% - 385px);
			width: calc(100% - 385px);
		}
		@include media-breakpoint-up(xl) {

			&:first-child {
				padding-right: 15px;
			}
			// &:first-child

			&:last-child {
				padding-left: 15px;
			}
			// &:last-child
		}
	}
	// .col-main

	article {
		background-color: $white;

		> header {
			padding: 19px 22px 22px 17px;

			& + .post-content {
				padding-top: 0.125rem;

				@include media-breakpoint-up(md) {
					padding-top: 1rem;
				}
			}
			// & + .post-content
		}
		// > header

		.post-title {
			text-transform: none;
			margin-bottom: 15px;

			@include media-breakpoint-up(md) {
				margin-bottom: 21px;
			}
		}
		// .post-title

		.post-datetime {
			line-height: 1.2;
			color: rgba($black, 0.4);
		}
		// .post-datetime

		.post-content {
			padding: 1.25rem 22px 30px;

			@include media-breakpoint-up(md) {
				padding: 1.5rem 34px 27px 17px;
			}

			.cover-author {
				display: block;
				margin-top: 40px;
				font-style: italic;
			}
		}
		// .post-content

		footer {
			padding-top: 1.5rem;

			p {
				font-style: italic;

				&:last-of-type {
					margin-bottom: 27px;

					@include media-breakpoint-up(md) {
						margin-bottom: 1rem;
					}
				}
				// &:last-of-type
			}
			// p

			hr {
				margin-bottom: 27px;
			}
			// hr

			.link-list {
				margin-bottom: 2.5rem;

				@include media-breakpoint-up(md) {
					margin-bottom: 2.75rem;
				}

				a {
					margin-right: 0.6875rem;
				}
				// a
			}
			// .link-list
		}
		// footer
	}
	// article

	.post-list {

		.post-list-item {

			&:last-of-type {
				margin-bottom: 36px;
			}
			// &:last-of-type
		}
		// 	.post-list-item
	}
	// .post-list
}
// .section-post
