body {
	position: relative;
	z-index: -3;
}

.home {
	#loadMoreContainer {
		margin-bottom: 50px;
	}
}

.page-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: $bg-gray;
	position: relative;
	z-index: -3;
}
// .page-wrapper

.container {
	width: 100%;
	max-width: 1170px;
	padding: 0 22px;
	margin: 0 auto;

	@include media-breakpoint-up(xl) {
		padding: 0;
	}
}
// .container

.main-content {
	margin-bottom: 91px;
	position: relative;
	top: 91px;
	z-index: -2;

	@include media-breakpoint-up(lg) {
		margin-bottom: 113px;
		top: 113px
	}
	section {
		margin-bottom: 50px;

		&:first-of-type {
			padding-top: 30px;
		}
		// &:first-of-type
	}
	// section
}
// .main-content

aside {
	margin-bottom: 50px;

	@include media-breakpoint-up(md) {
		margin-bottom: 30px;
	}

	&:last-of-type {
		margin-bottom: 0;
	}
	// &:last-of-type
}
// aside
