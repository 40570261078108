.section-gallery {

	> .container {
		padding: 0;
	}
	// > .container

	article {

		> .grid {

			@include media-breakpoint-up(lg) {
				flex-wrap: nowrap;
			}
		}
		// > .grid
	}
	// article

	.col-carousel {
		order: 2;
		width: 100%;

		@include media-breakpoint-up(lg) {
			order: 1;
			flex: 0 0 52.75%;
			width: 52.75%;
			padding: 30px 17px 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding-left: 70px;
		}
	}
	// .col-carousel

	.col-content {
		order: 1;
		padding: 30px 22px;

		@include media-breakpoint-up(lg) {
			order: 2;
			flex: 0 0 47.75%;
			width: 47.75%;
			padding-left: 17px;
			padding-right: 30px;
		}
		@include media-breakpoint-up(xl) {
			padding-right: 48px;
		}

		.author-name {
			font-weight: 500;
			color: #939393;
		}
		// .author-name
	}
	// .col-content

	.swiper-gallery {
		margin-bottom: 30px;

		.swiper-slide-active {
			cursor: pointer;
		}
		// .swiper-slide-active
	}
	// .swiper-gallery

	.swiper-gallery-thumbs {
		margin-bottom: 50px;
	}
	// .swiper-gallery-thumbs

	footer {
		width: calc(100% - 44px);
		padding: 1.125rem 0 1.875rem;
		margin: 0 auto;
		position: relative;

		@include media-breakpoint-up(lg) {
			padding: 45px 12px 37px;
		}

		&:before {
			display: block;
			content: '';
			width: 100%;
			height: 1px;
			@extend .brand-gradient;
			position: absolute;
			top: -1px;

			@include media-breakpoint-up(lg) {
				width: calc(100% - 24px);
			}
		}
		// &:before

		.link-list {
			margin-bottom: 35px;
		}
		// .link-list
	}
	// footer
}
// .section-gallery

.modal-gallery {

	.modal-content {
		width: 100%;
		max-width: 600px;

		@include media-breakpoint-up(md) {
			width: 80%;
		}
		@media all and (orientation: landscape){
			width: 50%;
			max-width: 1200px;
		}
		@include media-breakpoint-up(1900px) {
			width: 100%;
		}
	}
	// .modal-content

	.swiper-wrapper {
		margin-bottom: 30px;
	}
	// .swiper-wrapper

	.swiper-navigation {
		display: none;
		width: 100%;
		position: absolute;
		top: calc(50% - 39.5px);
		left: 0;

		@include media-breakpoint-up(md) {
			display: block;
		}

		.swiper-button {
			position: absolute;

			&.swiper-gallery-modal-version-button-next {
				right: 30px;

				@include media-breakpoint-up(lg) {
					right: 50px;
				}
				@include media-breakpoint-up(xl) {
					right: 100px;
				}
			}
			// &.swiper-gallery-modal-version-button-next

			&.swiper-gallery-modal-version-button-prev {
				left: 30px;

				@include media-breakpoint-up(lg) {
					left: 50px;
				}
				@include media-breakpoint-up(xl) {
					left: 100px;
				}
			}
			// &.swiper-gallery-modal-version-button-prev
		}
		// .swiper-button
	}
	// .swiper-navigation

	.swiper-pagination {
		bottom: 50px;

		.swiper-pagination-bullet {
			margin: 0 6px;
		}
		// .swiper-pagination-bullet
	}
	// .swiper-pagination

	.swiper-gallery-thumbs {
		padding: 0 22px;

		.swiper-wrapper {
			margin-bottom: 0;
		}
		// .swiper-wrapper
	}
	// .swiper-gallery-thumbs

	.swiper-slide {

		img {
			width: 100%;
		}
		// img
	}
	// .swiper-slide
}
// .modal-gallery
