.visually-hidden {
	width: 1px !important;
	height: 1px !important;
	clip: rect(1px,1px,1px,1px);
	white-space: nowrap;
	overflow: hidden;
	position: absolute !important;
}
// .visually-hidden

.mobile-only {

	@include media-breakpoint-up(md) {
		display: none !important;
	}
}
// .mobile-only

.desktop-only {

	@include media-breakpoint-down(md) {
		display: none;
	}
}
// .desktop-only

.brand-gradient {
	background: linear-gradient(270deg, $brand-red 0%, #098DD7 100%);
}
// .brand-gradient

hr.brand-gradient {
	display: block;
	content: '';
	width: 100%;
	height: 1px;
	border: none;
	margin: 0;
}
// hr.brand-gradient

.bg-img {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
// .bg-img

.bg-overlay {
	display: block;
	content: '';
	width: 100%;
	height: 100%;
	background-color: rgba($black, 0.2);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}
// .bg-overlay

.bg-white {
	background-color: $white;
}
// .bg-white

.bottom-gradient {
	display: inline-block;

	&:after {
		@extend .brand-gradient;
		display: block;
		content: '';
		width: 100%;
		height: 1px;
	}
	// &:after
}
// .bottom-gradient

.mb-0 {
	margin-bottom: 0 !important;
}
// .mb-0

.age-badge {
	flex-shrink: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 31px;
	height: 31px;
	font: {
		size: 1.375rem;
		weight: 500;
	}
	color: $white;
	padding-left: 2px;
	background-color: #BDBDBD;
	border-radius: 50%;
}
// .age-badge

#loadMoreButton {
	padding-bottom: 50px;
}
#loadMoreContainer {
	&.loading {
		filter: opacity(30%);
	}
}
