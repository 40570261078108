.author-card {

	aside & {

		img {
			min-width: 170px;
			width: 170px;
			height: 170px;
			border-radius: 50%;
			margin-bottom: 1rem;
		}
		// img

		.author-name {
			font-size: 1.5rem;
			line-height: 1.2;
			margin-bottom: 0.75rem;
		}
		// .author-name

		.link-list {
			margin-bottom: 0;

			a {
				font-size: 1.0625rem;
			}
			// a
		}
		// .link-list
	}
	// aside &

	img {
		width: 100%;
		max-width: 200px;
		height: auto;
		margin-bottom: 1.125rem;
	}
	// img

	.author-name {
		font-family: $font-primary;
		line-height: 1;
		text-transform: none;
		margin-bottom: 1.25rem;
	}
	// .author-name

	.link-list {

		a {
			margin-right: 11px;
		}
		// a
	}
	// .link-list
}
// .author-card
