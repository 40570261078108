.link-list {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;

	&-vertical {
		flex-direction: column;
		align-items: flex-start;

		a {
			display: block;
			margin: 0 0 11px;
		}

		li:last-of-type a {
			margin-bottom: 0;
		}
		// &:last-of-type
		// a
	}
	// &.link-list-vertical

	.age-badge {
		margin-right: 1.125rem;
	}
	// .age-badge

	.label {
		font-weight: 500;
		margin-right: 0.5rem;
	}
	// .label

	a {
		display: inline-block;
		font-size: 0.75rem;
		text-transform: uppercase;
		text-decoration: underline;
		color: rgba($black, 0.6);
		margin-right: 0.875rem;

		@include media-breakpoint-up(md) {
			margin-right: 11px;
		}

		&:hover {
			color: $black;
		}
		// &:hover
	}
	// a
}
// .link-list
