.section-hero {

	> .container {
		padding: 0;
		position: relative;
	}
	// > .container

	.bg-img {
		width: 100%;
		height: 100%;
		min-height: 380px;
	}
	// .bg-img

	.section-title {
		width: 100%;
		@extend .h2;
		line-height: 1;
		color: $white;
		padding: 0 22px;
		margin-bottom: 25px;
		position: absolute;
		bottom: 0;
		z-index: 2;

		@include media-breakpoint-up(md) {
			font-size: 3.125rem;
		}
		@include media-breakpoint-up(xl) {
			padding: 0 5.25rem;
		}
	}
	// .section-title
}
// .section-hero
