/**
 * Fonts
*/

@font-face {
	font-family: 'DIN Pro';
	src: url('../../static/fonts/DINPro.ttf');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'DIN Pro';
	src: url('../../static/fonts/DINPro-Italic.ttf');
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: 'DIN Pro';
	src: url('../../static/fonts/DINPro-Medium.ttf');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'DIN Pro';
	src: url('../../static/fonts/dinpro_bold.otf');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'DIN Pro';
	src: url('../../static/fonts/DINPro-Black.ttf');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: 'DIN Pro Condensed';
	src: url('../../static/fonts/DINPro-CondensedMedium.ttf');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'DIN Pro Condensed';
	src: url('../../static/fonts/DINPro-CondensedBold.ttf');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'DIN Pro Condensed Light';
	src: url('../../static/fonts/din-pro-condensed-light.ttf');
	font-weight: normal;
	font-style: normal;
}

$font-primary: 'DIN Pro', Arial, non-serif;
$font-secondary: 'DIN Pro Condensed', Arial, non-serif;

body {
	font: {
		family: $font-primary;
		size: 16px;
	}
	line-height: 1.1;
}
// body

.menu {

	.menu-link {
		font-family: $font-secondary;
		text-transform: uppercase;
	}
}
// .menu

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font: {
		family: $font-secondary;
		weight: 700;
	}
	text-transform: uppercase;
	margin-bottom: 1rem;

	a {
		&:hover {
			color: $brand-red;
		}
		// &:hover
	}
	// a
}

.h1 {
	font-size: 3.125rem;
}
// .h1

.h2 {
	font-size: 1.875rem;
}
// .h2

.h3 {
	font-size: 1.375rem;
}
// .h3

.h4 {
	font-size: 1.125rem;
}
// .h4

p {
	margin-bottom: 1.5rem;

	&:last-child {
		margin-bottom: 0;
	}
	// &:last-child

	&.lead {
		font: {
			size: 1.125rem;
			weight: 500;
		}
	}
	// &.lead
}
// p

i, em {
	font-style: italic;
}
// i, em

//blockquote

blockquote {
	padding-left: 14px;
	margin: 20px 0;
}
