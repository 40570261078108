.pagination-block {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;

  .pagination {
    display: flex;
    flex: 1;
    justify-content: space-around;
  }

  .current {
    color: $brand-red;
  }
}
