@font-face {
  font-family: 'Balkara';
  src: url('../../static/fonts/BalkaraFreeCondensed-npoekmu.me.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../../static/fonts/Lato-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Italic';
  src: url('../../static/fonts/Lato-Italic.ttf');
  font-weight: normal;
  font-style: normal;
}

.balkara {
  font-family: 'Balkara', serif;
}
.lato {
  font-family: 'Lato', serif;
}

.ancient-italic {
  font-style: italic;
  font-family: 'Lato-Italic', serif;
}

.fs-84 {
  font-size: 84px;
}
.fs-69 {
  font-size: 69px;
}
.fs-65 {
  font-size: 65px;
}
.fs-24 {
  font-size: 24px;
  font-weight: 400;
  line-height: 120%;
}
.fs-20 {
  font-size: 20px;
}
.fs-18 {
  font-size: 18px;
}
.fs-16 {
  font-size: 16px;
}

@media only screen and (max-width: 1280px) {
  .fs-84 {
    font-size: 42px;
  }
  .fs-69 {
    font-size: 34px;
  }
  .fs-65 {
    font-size: 32px;
  }
  .fs-24 {
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
  }
  .fs-20 {
    font-size: 16px;
  }
  .fs-18 {
    font-size: 16px;
  }
  .fs-16 {
    font-size: 16px;
  }
}

.relative {
  position: relative;
}
.text-center {
  text-align: center;
}
.align-self-center {
  align-self: center;
}

.full_wrap {
  max-width: 1920px;
}
.w-580 {
  max-width: 580px;
}
.w-830 {
  max-width: 830px;
}

.am {
  min-height: 953px;
}

.main-content section.longread {
  padding-top: 0;
}

.col-1 {
  @extend .col;

  @include media-breakpoint-up(xl) {
    flex: 0 0 calc(8% - 15px);
    width: calc(8% - 15px);
    padding: 0 15px;

    &:last-child {
      padding-right: 15px !important;
    }
    // &:last-child

    &:nth-child(even) {
      padding-right: 0 !important;
    }
    // &:nth-child(even)
  }
}
.col-2 {
  @extend .col;

  @include media-breakpoint-up(xl) {
    flex: 0 0 calc(16% - 15px);
    width: calc(16% - 15px);
    padding: 0 15px;

    &:last-child {
      padding-right: 15px !important;
    }
    // &:last-child

    &:nth-child(even) {
      padding-right: 0 !important;
    }
    // &:nth-child(even)
  }
}
.col-4 {
  @extend .col;

  @include media-breakpoint-up(xl) {
    flex: 0 0 calc(32% - 15px);
    width: calc(32% - 15px);
    padding: 0 15px;

    &:last-child {
      padding-right: 15px !important;
    }
    // &:last-child

    &:nth-child(even) {
      padding-right: 0 !important;
    }
    // &:nth-child(even)
  }
}
.col-5 {
  @extend .col;

  @include media-breakpoint-up(xl) {
    flex: 0 0 calc(40% - 15px);
    width: calc(40% - 15px);
    padding: 0 15px;

    &:last-child {
      padding-right: 15px !important;
    }
    // &:last-child

    &:nth-child(even) {
      padding-right: 0 !important;
    }
    // &:nth-child(even)
  }
}
.col-6 {
  @extend .col;

  @include media-breakpoint-up(xl) {
    flex: 0 0 calc(48% - 15px);
    width: calc(48% - 15px);
    padding: 0 15px;

    &:last-child {
      padding-right: 15px !important;
    }
    // &:last-child

    &:nth-child(even) {
      padding-right: 0 !important;
    }
    // &:nth-child(even)
  }
}
.col-8 {
  @extend .col;

  @include media-breakpoint-up(xl) {
    flex: 0 0 calc(64% - 15px);
    width: calc(64% - 15px);
    padding: 0 15px 0 0;

    &:last-child {
      padding-right: 15px !important;
    }
    // &:last-child

    &:nth-child(even) {
      padding-right: 0 !important;
    }
    // &:nth-child(even)
  }
}

.pl-650 {
  padding-left: 650px;
}
.pt-152 {
  padding-top: 152px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-16 {
  padding-left: 16px;
}

.mt-76 {
  margin-top: 76px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-40 {
  margin-top: 40px;
}

.red-bg {
  background-color: #930000;
}
.red-color {
  color: #F11;
}
.red800-color {
  color: #930000;
}
.white-color {
  color: $white;
}

.round-img {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  overflow: hidden;
}

.ring {
  display: flex;
  justify-content: center;
}

.first-screen {
  .fs-bg-image {
    background-image: url('../../static/img/longreads/ancient_moscow/kremlin1.png');
    .fs-header {
      font-size: 120px;
      color: $white;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      text-align: center;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 10.8px;
      margin-bottom: 25px;
    }
    .fs-subheader {
      color: $white;
      font-size: 20px;
      text-align: center;
      margin-top: 25px;
    }
  }
  .fs-bg-paper {
    background-image: url('../../static/img/longreads/ancient_moscow/paper.png');
    min-height: 510px;
    background-position: 0 -1075px;
    background-repeat: no-repeat;
    .fs-bg-paper-line {
      background-image: url('../../static/img/longreads/ancient_moscow/slavic_pattern.svg');
      background-repeat: repeat-x;
      min-height: 35px;
    }

    .fs-ksenz-container {
      .ksenz-photo {
      }
      .ksenz-text {
        .first {
          font-size: 20px;
          text-align: center;
          margin: 23px 0 45px;
        }
        .second {
          text-align: center;
          font-family: 'Balkara', serif;
          font-size: 24px;
        }
      }
    }
  }
}

.second-screen {
}

.big-line {
  max-height: 218px;
  position: relative;
  align-content: center;
  .semargl {
    height: auto;
    position: relative;
    top: 15px;
  }
}

.third-screen {

}

.slavic-ornament-bg {
  background-image: url('../../static/img/longreads/ancient_moscow/slavic_ornaments.png');
  background-repeat: no-repeat;
  height: 803px;
  margin-top: 30px;
  @media only screen and (min-width: 1900px) {
    .capische {
      top: -86px;
    }
  }
  @media only screen and (max-width: 1280px) {
    .align-self-end-md {
      align-self: end;
      margin-bottom: 0 !important;
    }
  }
}

.fourth_screen{

}

.modern-kremlin {
  background-image: url('../../static/img/longreads/ancient_moscow/kremlin5.jpg');
  background-repeat: no-repeat;
  height: 884px;
}

.fifth-screen {
}
