.official-disclaimer {
  position: relative;
}
.official-disclaimer-show{
  font-size: 12px;
  cursor: pointer;
}
.official-disclaimer-hide {
  display: none;
}
.official-disclaimer-show:hover + .official-disclaimer-hide {
  display: block;
  font-size: 12px;
  position: absolute;
  top: 15px;
}