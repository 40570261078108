.years_header {
  display: block;
}

.years_subheader {
  background-image: url("https://moscowchanges.ru/wp-content/uploads/2023/07/background_blue.jpg");
  min-height: 100vh;
  margin-bottom: 40px;
  padding: 40px 0;
  color: #ffffff;
  .years_block-stat {
    .years_block-stat_column {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      .years_block-icon {
        display: flex;
        justify-content: start;
        align-items: center;
        column-gap: 19px;
        .years_block-icon_img {
          width: 66px;
          height: 66px;
          min-width: 66px;
        }
        .years_block-icon_text {
          min-width: 208px;
          .number {
            font-size: 44px;
            text-transform: uppercase;
            font-family: "DIN Pro Condensed", Arial, non-serif;
          }
          .letter {
            font-size: 22px;
            font-family: "DIN Pro Condensed Light", Arial, non-serif;
            text-transform: uppercase;
          }
        }
      }
      .border-bottom-1 {
        border-bottom: solid 1px #ffffff;
        padding-bottom: 30px;
        margin-bottom: 20px;
      }
    }
  }
}

.ol{
  margin-bottom: 80px;
  ol{
    li{
      color: #1863BA;
      font-size: 28px;
      a{
        color: #1863BA;
      }
    }
  }
}

.years_block-text{
  margin-bottom: 40px;
  font-size: 16px;
}

.years_block-h2{
  h2{
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  margin-top: 40px;
  margin-bottom: 24px;
}

.years_grey-bg{
  background-color: #D9D9D9;
  height: 405px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.years_block-quote {
  display: flex;
  border-top: solid 1px #000000;
  border-bottom: solid 1px #000000;
  padding: 35px 50px;
  margin-bottom: 40px;
  column-gap: 50px;
  justify-content: space-evenly;
  align-items: flex-start;

  .years_block-quote_face {
    min-width: 170px;
    height: 170px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width:100%;
      height:100%;
      object-fit: cover;
    }
  }
  .years_block-quote_text {
    font-style: italic;
    font-size: 24px;
    line-height: 120%;
    .person {
      font-size: 20px;
      .post{
        font-size: 16px;
      }
    }
  }
}

.years_block-gallery {
  margin-bottom: 40px;
  .swiper {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .swiper-gallery-longread-thumbs {
    .swiper-gallery-longread-thumbs-carousel {
      width: 100% !important;
      .swiper-wrapper {
        justify-content: flex-end !important;
      }
    }
  }
}

.years_block-two-columns {
  column-gap: 20px;
  .col-66 {
    padding-right: 0 !important;
  }
  .col-33 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .years_block-two-columns_first {
    display: block;
  }
  .years_block-two-columns_second {
    .column_second-header {
      font-size: 44px;
      text-transform:uppercase;
    }
    .column_second-block-stat {
      margin-bottom: 20px;
      .number {
        font-size: 33px;
        text-transform: uppercase;
      }
      .letter-18 {
        font-size: 18px;
        text-transform: uppercase;
      }
      .letter-18-slim {
        font-size: 18px;
        font-family: "DIN Pro Condensed Light", Arial, non-serif;
        text-transform: uppercase;
      }
    }
  }
}