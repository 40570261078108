.grid {
	display: flex;
	flex-direction: column;
	width: 100%;

	@include media-breakpoint-up(md) {
		flex-flow: row wrap;
	}
}
// .grid

.col {
	box-sizing: content-box;
	margin-bottom: 30px;

	@include media-breakpoint-up(md) {
		padding: 0 15px;
	}

	&:first-child {

		@include media-breakpoint-up(md) {
			padding-left: 0 !important;
		}
	}
	// &:first-child

	&:last-child {
		margin-bottom: 0 !important;

		@include media-breakpoint-up(md) {
			padding-right: 0 !important;
		}
	}
	// &:last-child

	&:only-child {
		flex: 0 0 100%;
		width: 100%;
	}
	// &:only-child
}
// .col

.col-50 {
	@extend .col;

	@include media-breakpoint-up(md) {
		@include col-50-in-row;
	}
	@include media-breakpoint-up(xl) {
		flex: 0 0 calc(50% - 15px);
		width: calc(50% - 15px);
		padding: 0 15px;

		&:last-child {
			padding-right: 15px !important;
		}
		// &:last-child

		&:nth-child(even) {
			padding-right: 0 !important;
		}
		// &:nth-child(even)
	}
}
// .col-50

.col-33 {
	@extend .col;

	@include media-breakpoint-up(md) {
		flex: 0 0 calc(33.33333% - 12px);
		width: calc(33.33333% - 12px);
		padding: 0 9px;

		&:nth-child(3n) {
			padding-right: 0 !important;
		}
		// &:nth-child(3n)

		&:nth-child(3n + 4) {
			padding-left: 0 !important;
		}
		// &:nth-child(3n + 1)

		&:only-child {
			flex: 0 0 calc(33.33333% - 12px);
			width: calc(33.33333% - 12px);
		}
		// &:only-child

		&:nth-last-child(2),
		&:nth-last-child(3) {
			margin-bottom: 0 !important;
		}
		// &:nth-last-child(2), &:nth-last-child(3)
	}
	@include media-breakpoint-up(xl) {
		flex: 0 0 calc(33.33333% - 20px);
		width: calc(33.33333% - 20px);
		padding: 0 15px;

		&:only-child {
			flex: 0 0 calc(33.33333% - 20px);
			width: calc(33.33333% - 20px);
		}
		// &:only-child
	}
}
// .col-33

.col-66 {
	@extend .col;

	@include media-breakpoint-up(md) {
		flex: 0 0 calc(66.66667% - 6px);
		width: calc(66.66667% - 6px);
		padding: 0 9px;
		margin-bottom: 0 !important;

		&:only-child {
			flex: 0 0 calc(66.66667% - 6px);
			width: calc(66.66667% - 6px);
		}
		// &:only-child
	}
	@include media-breakpoint-up(xl) {
		flex: 0 0 calc(66.66667% - 10px);
		width: calc(66.66667% - 10px);
		padding: 0 15px;

		&:only-child {
			flex: 0 0 calc(66.66667% - 10px);
			width: calc(66.66667% - 10px);
		}
		// &:only-child
	}
}
// .col-66

.col-25 {
	@extend .col;

	@include media-breakpoint-up(md) {
		flex: 0 0 calc(25% - 13.5px);
		width: calc(25% - 13.5px);
		padding: 0 9px;

		&:nth-child(4n) {
			padding-right: 0 !important;
		}
		// &:nth-child(4n)

		&:nth-child(4n + 1) {
			padding-left: 0 !important;
		}
		// &:nth-child(4n + 1)
	}
	@include media-breakpoint-up(xl) {
		flex: 0 0 calc(25% - 22.5px);
		width: calc(25% - 22.5px);
		padding: 0 15px;
	}
}
// .col-25

.col-main {
	flex: 1 1 auto;
	width: auto;
}
// .col-main

.col-sidebar {
	flex: 0 1 content;
	width: auto;
}
// .col-sidebar
