/* Colors */

$brand-red: #FC070C;

$bg-gray: #F3F3F3;

$black: #000;
$white: #fff;

$gray-1: #E1E1E1;

body {
	color: $black;
}
// body

a {
	color: $black;
	@include transition;
}
// a
