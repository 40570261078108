.post-list {
	display: flex;
	flex-wrap: wrap;

	&.bg-white {
		padding: 19px 22px 22px;
		box-sizing: border-box;
		margin: 0 -22px;

		@include media-breakpoint-up(md) {
			margin: 0;
		}
		@include media-breakpoint-up(lg) {
			padding: 19px 34px;
		}

		.col-sidebar & {

			@include media-breakpoint-up(lg) {
				padding: 20px 25px 25px;
			}
		}
		// .col-sidebar &
	}
	// &.bg-white

	.post-list-item {
		flex: 0 0 100%;
		margin-bottom: 1.1875rem;

		&:last-of-type {
			margin-bottom: 5rem;

			@include media-breakpoint-up(md) {
				margin-bottom: 2.875rem;
			}
		}
		// &:last-of-type

		.post-title {
			font: {
				family: $font-primary;
				size: 1.125rem;
				weight: 400;
			}
			text-transform: none;
			margin-bottom: 7px;

			a {

				&:hover {
					color: $black;
					text-decoration: underline;
				}
				// &:hover
			}
			// a
		}
		// .post-title

		.post-datetime {
			font-size: 0.75rem;
			color: rgba($black, 0.4);
		}
		// .post-datetime
	}
	// post-list-item

	.section-link {
		align-self: flex-end;
		font: {
			size: 0.875rem;
			weight: 500;
		}
		text-transform: uppercase;
		color: rgba($black, 0.4);

		@include media-breakpoint-up(md) {
			font-size: 0.875rem;
		}

		&:hover {
			color: $black;
		}
		// &:hover
	}
	// .section-link
}
// .post-list
