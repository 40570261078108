.section-authors-archive {

	> .container {

		> .grid {

			@media all and (orientation: landscape) and (min-width: 600px) {
				flex-flow: row nowrap;
			}
			@include media-breakpoint-up(md) {
				flex-wrap: nowrap;
			}
		}
		// > .grid
	}
	// > .container

	.col-sidebar {
		padding: 0.75rem 22px 50px;
		margin: 0 -22px;

		@media all and (orientation: landscape) and (min-width: 600px) {
			margin: 0;
		}
		@include media-breakpoint-up(md) {
			margin: 0;
		}
		@include media-breakpoint-up(lg) {
			padding-left: 2.125rem;
			padding-right: 1.75rem;
		}

		.section-title {
			margin-bottom: 1.25rem;
		}
		// .section-title

		.link-list {
			margin-bottom: 0;

			a {
				font-size: 1.0625rem;
				margin-bottom: 1.25rem;

				&:last-of-type {
					margin-bottom: 0;
				}
				// &:last-of-type
			}
			// a
		}
		// .link-list
	}
	// .col-sidebar

	.col-main {
		padding-bottom: 30px;

		@media all and (orientation: landscape) and (min-width: 600px) {
			padding: 29px 0 30px 1.75rem;
		}
		@include media-breakpoint-up(md) {
			padding: 29px 0 53px 1.75rem;
		}
		@include media-breakpoint-up(xl) {
			padding-right: 35px;
		}
	}
	// .col-main

	.filter-panel {

		form {
			display: inline-flex;
			align-items: center;
			width: 100%;
			position: relative;
			margin-bottom: 2.25rem;

			@media all and (orientation: landscape) and (min-width: 600px) {
				max-width: 442px;
				float: right;
			}
			@include media-breakpoint-up(md) {
				max-width: 442px;
				float: right;
			}
		}

		input[type="text"] {
			width: 100%;
			padding-left: 22px;

			@media all and (orientation: landscape) and (min-width: 600px) {
				padding-left: 14px;
			}
			@include media-breakpoint-up(md) {
				padding-left: 14px;
			}
		}
		// input[type="text"]

		input[type="submit"] {
			appearance: none;
			text-indent: -99999px;
			@extend .icon;
			@extend .icon-search;
			width: 18px;
			height: 17px;
			background-size: cover !important;
			border: none;
			opacity: 0.4;
			position: absolute;
			top: calc(50% - 9.5px);
			right: 6px;

			&:hover {
				opacity: 0.8;
			}
			// &:hover
		}
		// input[type="submit"]
	}
	// .filter-panel

	.authors-grid {
		clear: both;

		@media all and (orientation: landscape) and (min-width: 600px) {
			flex-flow: row wrap;
		}

		> .col-25 {

			@media all and (orientation: landscape) and (min-width: 600px) and (max-width: 767px) {
				@include col-50-in-row;
			}
		}
		// > .col-25

		.author-card {

			img {

				@media all and (orientation: landscape) and (min-width: 600px) and (max-width: 767px) {
					max-width: 100%;
				}
			}
			// img

			.link-list {
				margin-bottom: 0;
			}
			// .link-list
		}
		// .author-card

		.author-name {
			margin-bottom: 0;
		}
		// .author-name
	}
	// .authors-grid
}
// .section-authors-archive
