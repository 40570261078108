.swiper-posts {

	.post-img {
		width: 100%;
		height: auto;
		margin-bottom: 0.75rem;
	}
	// .post-img

	.link-list {
		margin-bottom: 0.75rem;
	}
	// .link-list

	.post-title {
		@extend .h4;
		min-height: 116px;
		margin-bottom: 6px;
	}
	// .post-title
}
// .swiper-posts
