.swiper-hero {
	height: 380px;

	.swiper-hero-img {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 1;

		@media all and (orientation: landscape) and (min-width: 600px) {
			display: none;

			&.mobile-only {
				display: none;
			}
			// &.mobile-only

			&.desktop-only {
				display: block;
			}
			// &.desktop-only
		}
	}
	// .swiper-hero-img

	.swiper-hero-text {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		width: 100%;
		height: 100%;
		color: $white;
		padding: 50px 22px 25px 22px;
		box-sizing: border-box;
		position: absolute;
		z-index: 2;

		@include media-breakpoint-up(xl) {
			max-width: 1170px;
			padding-left: 5.25rem;
			margin-left: calc(50% - 585px);
		}
	}
	// .swiper-hero-text

	.swiper-hero-title {
		font: {
			family: $font-secondary;
			size: 1.875rem;
			weight: 700;
		}
		text-transform: uppercase;
		color: $white;
		margin-bottom: 25px;

		@include media-breakpoint-up(md) {
			font-size: 3.125rem;
		}

		a {
			color: $white;
		}
	}
	// .swiper-hero-title

	.swiper-hero-tags {

		a {
			display: inline-block;
			font-size: 0.75rem;
			text-transform: uppercase;
			text-decoration: underline;
			color: $white;
			margin-right: 29px;

			@include media-breakpoint-up(md) {
				font-size: 0.875rem;
				margin-right: 1.0625rem;
			}
		}
		// a
	}
	// .swiper-hero-tags

	.swiper-pagination {
		text-align: left;
		bottom: 25px;
		left: 22px;

		@include media-breakpoint-up(xl) {
			max-width: 1170px;
			padding-left: 5.25rem;
			left: calc(50% - 585px);
		}

	}
	// .swiper-pagination
}
// .swiper-hero
