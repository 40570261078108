.post-card {
	height: 100%;
	background-color: $white;

	picture {
		display: flex;
		flex-direction: column;

		&:after {
			display: block;
			content: '';
			width: 100%;
			height: 3px;
			@extend .brand-gradient;
		}
		// &:after

		img {
			width: 100%;
			height: auto;
		}
		// img
	}
	// .picture

	.post-card-wrapper {
		display: flex;
		flex-direction: column;
		padding: 1.375rem 1.375rem 9px;

		@include media-breakpoint-up(md) {
			min-height: 177px;
			padding-left: 1rem;
		}
	}
	// .post-card-wrapper

	.post-title {
		flex-grow: 1;
		@extend .h3;
		margin-bottom: 2.5rem;
	}
	// .post-title

	.post-datetime {
		font-size: 0.875rem;
		line-height: 1.2;
		color: rgba($black, 0.4);
	}
	// .post-datetime
}
// .post-card
