.social-links {
	display: flex;
	align-items: center;

	.label {
		font-weight: 500;
		margin-right: 5px;
	}
	// .label

	a {
		display: inline-block;
		line-height: 0;
		padding: 0.25rem 0.5rem;
	}
	// a

	.icon {
		width: 31px;
		height: 31px;
	}
	// .icon
}
// .social-links
