.single {
  .col-main {
    a {
      text-decoration: underline;
    }
  }
}
.longread {

  section {
    margin-bottom: 0;
  }

  h2 {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 30px;
    color: #164378
  }

  img {
    width: 100%;
  }



  ul{
    margin-bottom: 1.5rem;
    li{
      list-style: disc;
      margin-left: 3rem;
      &:not(:last-child){
        margin-bottom: 10px;
      }
    }
  }

  &-header {
    text-align: center;color: #fff;

    .h1 {
      padding: 30px;
      @include media-breakpoint-up(lg) {
        padding: 60px 0;
      }
    }

    .text {
      background: rgba(22, 67, 120, 0.8);
      padding: 30px;
      @include media-breakpoint-up(lg) {
        padding: 30px 60px;
      }
    }
  }

  &-content{
    .content {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 30px;
      @include media-breakpoint-up(lg) {
        padding-top: 60px;
        padding-left: 60px;
        padding-right: 60px;
        flex-wrap: nowrap;
      }

      .text {
        padding: 0 30px;
        @include media-breakpoint-up(lg) {
          flex-basis: 70%;
          padding:0 60px 0 0;
        }
        strong {
          display: block;
          font-weight: bold;
          margin-bottom: 15px;
        }
        &.left {
          padding-right: 30px;
          @include media-breakpoint-up(lg) {
            padding-right:0 ;
          }
        }
      }

      .sidebar {
        @include media-breakpoint-up(lg) {
          flex-basis: 30%;
          &.left {
            padding-right: 60px;
          }
        }
      }
      &~.media{
        padding-top: 30px;
        background: #fff;
      }
    }

    .stats {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 30px;
      @include media-breakpoint-up(lg) {
        padding-top: 120px;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 60px;
        grid-row-gap: 60px;
      }

      &-item {
        display: flex;

        .icon {
          flex-basis: 45px;
          margin-right: 25px;
        }

        .h2 {
          font-size: 36px;
          font-weight: bold;
          color: #164378
        }
      }
    }

    .sidebar {
      padding-top: 60px;
      @include media-breakpoint-up(lg) {
        padding-top: 0;
      }
    }
  }

  .hero-image {
    position: relative;
    .text {
      padding: 30px;
      @include media-breakpoint-up(lg) {
        color: #fff;
        position: absolute;
        top: 60px;
        left: 60px;
        padding: 0;
      }
    }
  }

  .bg-blue {
    background-color: #164378;
    color: #fff;
    padding: 30px 60px;
    .h2{
      color: #fff;
    }
  }

  .split {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &>div {
      @include media-breakpoint-up(lg) {
        flex-basis: calc(50% - 15px);
        flex-wrap: nowrap;
      }
    }
  }
}
