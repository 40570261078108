body.modal-opened {
	height: 100vh;
	overflow: hidden;
}
// body.modal-opened

.modal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba($black,0.8);
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -99999;
	@include transition;

	&.active {
		display: flex;
		opacity: 1;
		z-index: 99999;
	}
	// &.active
}
// .modal
