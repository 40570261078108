.section-posts-carousel {

	&.bg-white {
		padding-top: 29px;
		padding-bottom: 33px;
	}
	// &.bg-white

	&:last-of-type {
		margin-bottom: 0;
	}
	// &:last-of-type

	.section-title {
		margin-bottom: 2.5rem;
	}
	// .section-title
}
// .section-posts-carousel
