.main-header {
	background-color: $white;
	overflow: visible;
	position: relative;

	@include media-breakpoint-up(lg) {

		.menu-link {
			font: {
				size: 1.25rem;
				weight: 500;
			}
			padding: 0.5rem;
			position: relative;

			@include media-breakpoint-up(xl) {
				font-size: 1.5rem;
			}

			&:before {
				display: block;
				content: '';
				width: 0;
				height: 2px;
				background-color: $black;
				position: absolute;
				bottom: 5px;
				left: 50%;
				@include transition;
			}
			// &:before

			&:hover {

				&:before {
					width: calc(100% - 1rem);
					left: 0.5rem;
				}
				// &:before
			}
			// &:hover
		}
		// .menu-link
	}

	> nav {

		&:before {
			display: block;
			content: '';
			width: 100%;
			height: 6px;
			@extend .brand-gradient;
		}
		// &:before
	}
	// > nav

	.menu-item {

		&.current-menu-item {

			.menu-link {
				color: $brand-red;
			}
			// .menu-link
		}
		// &.current-menu-item
	}
	// .menu-item

	hr.brand-gradient {
		height: 2px;
	}
	// hr.brand-gradient

	.footer-only {
		display: none;
	}
	// .footer-only
}
// .main-header
