.swiper-navigation {

	&.swiper-navigation-light {

		.swiper-button {
			background-image: url("data:image/svg+xml,%3Csvg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 19.5L9.5 10.5L1 0.999999' stroke='%23989898'/%3E%3C/svg%3E%0A");
		}
		// .swiper-button
	}
	// &.swiper-navigation-light

	.swiper-button {
		width: 9px;
		height: 19px;
		background: center center / cover url("data:image/svg+xml,%3Csvg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 19.5L9.5 10.5L1 0.999999' stroke='black'/%3E%3C/svg%3E%0A");
		top: auto;
		bottom: 0;
		z-index: 99;
		cursor: pointer;

		&.swiper-button-prev {
			transform: rotate(180deg);
			left: 0;
		}
		// &.swiper-button-prev

		&.swiper-button-next {
			right: 0;
		}
		// &.swiper-button-next

		&.swiper-button-disabled {
			opacity: 0.2;
			cursor: default;
		}
		// &.swiper-button-disabled

		&:after {
			content: '';
		}
		// &:after
	}
	// .swiper-button
}
// .swiper-navigation

.swiper-pagination {
	bottom: 0;

	&.swiper-pagination-light {

		.swiper-pagination-bullet {
			background-color: $white;
			// &.swiper-pagination-bullet-active
		}
		// .swiper-pagination-bullet
	}
	// &.swiper-pagination-light

	.swiper-pagination-bullet {
		width: 6px;
		height: 6px;
		background-color: $black;
		border-radius: 0;
		margin: 0 6px;
		opacity: 0.4;

		&:first-child {
			margin-left: 0;
		}
		// &:first-child

		&.swiper-pagination-bullet-active {
			opacity: 1;
		}
	}
	// .swiper-pagination-bullet
}
// .swiper-pagination

.swiper-gallery-thumbs {
	margin-bottom: 50px;

	.swiper-slide {
		cursor: pointer;
	}
	// .swiper-slide
}
// .swiper-gallery-thumbs
