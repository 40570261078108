.main-navigation-desktop {
	display: none;
	width: 100%;
	background-color: $white;
	overflow: visible;
	position: fixed;

	@include media-breakpoint-up(lg) {
		display: block;
	}

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 107px;
		margin: 0 auto;
		position: relative;

		@include media-breakpoint-up(xxl) {
			max-width: 1920px;
			padding: 0 82px;
		}
	}
	// .container

	.logo {
		flex-shrink: 0;
	}
	// .logo

	.logo-image {
		width: 145px;
		height: auto;

		@include media-breakpoint-up(xl) {
			width: 177px;
		}
	}
	// .logo-image

	.menu {
		flex: 1 1 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-right: 10px;

		@include media-breakpoint-up(xl) {
			margin-right: 20px;
		}
	}
	// .menu

	.menu-item {
		margin-right: 20px;

		@include media-breakpoint-up(xl) {
			margin-right: 25px;
		}
		@include media-breakpoint-up(xxl) {
			margin-right: 34px;
		}

		&.current-menu-item {

			.menu-link {

				&:hover {
					cursor: default;

					&:before {
						display: none;
					}
					// &:before
				}
				// &:hover

				&:after {
					display: block;
					width: calc(100% - 1rem);
					height: 1px;
					@extend .brand-gradient;
					position: absolute;
					bottom: -34px;
					left: 0.5rem;
					@include transition;

					@include media-breakpoint-up(lg) {
						content: '';
					}
					@include media-breakpoint-up(xl) {
						bottom: -32px;
					}
				}
				// &:after
			}
			// .menu-link
		}
		// &.current-menu-item
	}
	// .menu-item

	.hamburger {
		flex-shrink: 0;
		height: calc(22px + 1rem);
		right: -0.5rem;

		@include media-breakpoint-up(1600px) {
			height: calc(35px + 1rem);
		}

		.hamburger-line {
			width: 35px;
			height: 4px;

			@include media-breakpoint-up(1600px) {
				width: 56px;
				height: 7px;
			}

			&:before {
				@extend .hamburger-line;
				top: -9px;

				@include media-breakpoint-up(1600px) {
					top: -14px;
				}
			}
			// &:before

			&:after {
				@extend .hamburger-line;
				bottom: -5px;

				@include media-breakpoint-up(1600px) {
					bottom: -7px;
				}
			}
			// &:after
		}
		// .hamburger-line
	}
	// .hamburger
}
// .main-navigation-desktop


.search-form-trigger {
	flex-shrink: 0;
	display: inline-block;
	font-size: 0;
	margin-right: 10px;

	@include media-breakpoint-up(xl) {
		margin-right: 40px;
	}
	@include media-breakpoint-up(xxl) {
		margin-right: 52px;
	}
}
// .search-form-trigger

.icon-search {
	width: 24px;
	height: 248px;
	background-size: 20px 20px !important;

	@include media-breakpoint-up(1600px) {
		width: 32px;
		height: 32px;
		background-size: 28px 28px !important;
	}
}
// .icon-search

.search-form-desktop {
	padding: 22px 22px 20px;
	background-color: $bg-gray;
	position: fixed;
	right: 0;
	top: 91px;
	z-index: -1;
	@include transition(0.1s);
	transform: translateY(-100%);

	@include media-breakpoint-up(lg) {
		top: 112px;
	}
	@include media-breakpoint-up(xl) {
		padding-right: calc(50% - 585px);
	}
	@include media-breakpoint-up(xxl) {
		padding-right: 82px;
	}
	@include media-breakpoint-up(1920px) {
		margin-right: calc(50% - 960px);
	}

	&.active {
		transform: translateY(0);
	}
	// &.active

	&.shown {
		z-index: 1;
	}
	// &.shown

	input[type="text"] {
		min-width: 403px;
		font-size: 1.5rem;
	}
	// input[type="text"]

	input[type="submit"] {
		width: 20px;
		height: 20px;
		background-size: 17px 17px !important;
		margin-right: 1rem;
	}
	// input[type="submit"]
}
// .search-form-desktop

.desktop-menu-collapsible-panel {
	padding: 32px 22px 40px 48px;
	background-color: $bg-gray;
	position: fixed;
	right: 0;
	top: 91px;
	z-index: -1;
	transition: 0.1s all ease-in-out;
	transform: translateY(-100%);

	@include media-breakpoint-up(lg) {
		top: 112px;
	}
	@include media-breakpoint-up(xl) {
		padding: 51px calc(50% - 585px) 58px 78px;
	}
	@include media-breakpoint-up(xxl) {
		min-width: 302px;
		padding-right: 82px;
	}
	@include media-breakpoint-up(1920px) {
		margin-right: calc(50% - 952px);
	}

	&.active {
		transform: translateY(0);
	}
	// &.active

	&.shown {
		z-index: 1;
	}
	// &.shown

	.menu {
		flex-direction: column;
		align-items: flex-end;
		margin-right: 0;
		position: relative;
		right: -0.5rem;

		.menu-item {
			text-align: right;
			margin: 0 0 35px;

			&:last-child {
				margin-bottom: 31px;
			}
			// &:last-child
		}
		// .menu-item
	}
	// .menu

	.social-links {
		justify-content: flex-end;
		padding-top: 28px;
		position: relative;
		right: -8px;
	}
	// .social-links
}
// .desktop-menu-collapsible-panel
