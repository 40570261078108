.single-slider {
  position: relative;

  .swiper-pagination {
    text-align: left;
    bottom: 75px;
    left: 22px;

    @include media-breakpoint-up(xl) {
      max-width: 1170px;
      padding-left: 1.25rem;
      left: calc(50% - 585px);
    }
  }

  .swiper-navigation {
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 30px;
    bottom: -10px;
    position: relative;

    .swiper-button-prev {
      left: 22px;
    }
    // .swiper-button-prev

    .swiper-button-next {
      left: 44px;

    }
    // .swiper-button-next
  }
}
