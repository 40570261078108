.search-form {
	display: flex;
	align-items: center;

	input[type="text"] {
		order: 2;
		width: 100%;
		font: {
			family: $font-secondary;
			size: 1.125rem;
			weight: 500;
		}
		text-transform: uppercase;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid #797979;

		@include placeholder {
			color: #C8C8C8;
			@include transition;
		}

		&:active, &:focus {
			@include placeholder {
				color: rgba(#C8C8C8, 0.4);
			}
		}
		// &:active, &:focus
	}
	// input[type="text"]

	input[type="submit"] {
		order: 1;
		width: 22px;
		height: 22px;
		text-indent: -99999px;
		@extend .icon;
		@extend .icon-search;
		background-size: 18px 18px !important;
		border: none;
		box-shadow: none;
		margin-right: 15px;
		cursor: pointer;
	}
	// input[type="submit"]
}
// .search-form
